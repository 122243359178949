import Api from '../../../services/Api';

export default {
  namespaced: true,
  state: {
    aggregateInfo: [
      { Agronomía: { analyzed: 0, in_process: 0 } },
      { Alimentaria: { analyzed: 0, in_process: 0 } },
      { 'Medio Ambiente': { analyzed: 0, in_process: 0 } },
      { Minería: { analyzed: 0, in_process: 0 } },
      { 'Salud y Seguridad': { analyzed: 0, in_process: 0 } },
    ],
    reportSampleId: '',
    reportSampleCode: '',
    currentSample: null,
    disableContext: false,
    allFilterParams: {},
    last_sample_id: null,
    isDownloadingReport: false,
    sampleFetchedFromEtl: false,
    lastRouteHome: false,
    lastRouteEnvStats: false,
    lastRouteGeotoolboxSamples: false,
  },
  actions: {
    setDisableContext({ commit }, value) {
      commit('setDisableContext', value);
    },
    async getSamplesWithParameters(
      { rootState, state },
      monitoring = null
    ) {
      try {
        const STATUS_FINISHED = '3';
        const extraParams = monitoring != null ? { monitoring } : {};
        const excelStatus =
          monitoring !== true ? { status: STATUS_FINISHED } : {};

        const xhr = await Api.getSamplesWithParametersData(
          {
            ...state.allFilterParams,
            ...extraParams,
            ...excelStatus,
          },
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);

        return Promise.resolve(response[0].Samples);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSamplesZip({ rootState }, value) {
      try {
        const xhr = await Api.getSamplesReports(
          rootState.samples.allFilterParams,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setAllFilterParams({ commit }, value) {
      commit('setAllFilterParams', value);
    },
    async getAggregateInfo({ commit, rootState }) {
      try {
        const xhr = await Api.getAggregateInfo(
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);
        commit('SET_AGGREGATEINFO', response);
        return Promise.resolve();
      } catch (error) {
        commit('SET_AGGREGATEINFO_ERROR');
        return Promise.reject(error);
      }
    },
    getCurrentSampleBasedOnFilter({ state }) {
      return state.currentSample;
    },
    async getCurrentSample({ commit, rootState, dispatch }) {
      try {
        const xhr = await Api.getSampleInfo(
          rootState.filter.currentSampleId,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        dispatch('setReportSampleId', response.sample_id);
        dispatch(
          'foodSamples/setIsNoRp',
          response.value_report === 'CMA',
          {
            root: true,
          }
        );

        commit('setCurrentSample', response);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getReport({ state }, docType = 1) {
      try {
        const xhr = await Api.getSampleReport(
          state.reportSampleId,
          docType
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve({
          name: response.data[0].nombreDocumento,
          pdfB64: response.data[0].documento,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSampleById({ rootState }, id) {
      try {
        const xhr = await Api.getSampleInfo(
          id,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setReportSampleId({ commit }, value) {
      commit('setReportSampleId', value);
    },
    setReportSampleCode({ commit }, value) {
      commit('setReportSampleCode', value);
    },
    async getSampleFilter(
      { rootState },
      { atc, attribute, sampleId }
    ) {
      try {
        const xhr = await Api.getAttributeList(
          atc,
          attribute,
          sampleId,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSampleFilterGeotoolbox(
      { rootState },
      { atc, attribute, sampleId, sampleTypeForGeometry }
    ) {
      try {
        const xhr = await Api.getAttributeListGeotoolbox(
          atc,
          attribute,
          sampleId,
          sampleTypeForGeometry,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSampleFilterPhytomonitoring(
      { rootState },
      { atc, attribute, sampleId, sampleTypeForGeometry }
    ) {
      try {
        const xhr = await Api.getAttributeListPhytomonitoring(
          atc,
          attribute,
          sampleId,
          sampleTypeForGeometry,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSampleFarms({ rootState }, { atc }) {
      try {
        const xhr = await Api.getSamplesFarms(
          atc,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSampleParcels({ rootState }, { atc, farm }) {
      try {
        const xhr = await Api.getSamplesParcels(
          atc,
          farm,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setCurrentSample({ commit }, value) {
      commit('setCurrentSample', value);
    },
    async getLastSampleId({ rootState, commit }, parcelId) {
      try {
        const xhr = await Api.getLastSampleId(
          parcelId,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);

        commit('setLastSampleId', response ?? null);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setIsDownloadingReport({ commit }, value) {
      commit('setIsDownloadingReport', value);
    },
    setSampleFetchedFromEtl({ commit }, value) {
      commit('SET_SAMPLE_FETCHED_FROM_ETL', value);
    },
    setLastRouteHome({ commit }, value) {
      commit('SET_LAST_ROUTE_HOME', value);
    },
    setLastRouteEnvStats({ commit }, value) {
      commit('SET_LAST_ROUTE_ENV_STATS', value);
    },
    setLastRouteGeotoolboxSamples({ commit }, value) {
      commit('SET_LAST_ROUTE_GEOTOOLBOX', value);
    },
  },
  mutations: {
    SET_AGGREGATEINFO(state, value) {
      state.aggregateInfo = value;
    },
    SET_AGGREGATEINFO_ERROR(state) {
      state.aggregateInfo = [
        { Agronomía: { analyzed: 0, in_process: 0 } },
        { Alimentaria: { analyzed: 0, in_process: 0 } },
        { 'Medio Ambiente': { analyzed: 0, in_process: 0 } },
        { Minería: { analyzed: 0, in_process: 0 } },
        { 'Salud y Seguridad': { analyzed: 0, in_process: 0 } },
      ];
    },
    setReportSampleId(state, value) {
      state.reportSampleId = value;
    },
    setCurrentSample(state, value) {
      state.currentSample = value;
    },
    setAllFilterParams(state, value) {
      state.allFilterParams = value;
    },
    setReportSampleCode(state, value) {
      state.reportSampleCode = value;
    },
    setDisableContext(state, value) {
      state.disableContext = value;
    },
    setLastSampleId(state, value) {
      state.last_sample_id = value;
    },
    setIsDownloadingReport(state, value) {
      state.isDownloadingReport = value;
    },
    SET_SAMPLE_FETCHED_FROM_ETL(state, value) {
      state.sampleFetchedFromEtl = value;
    },
    SET_LAST_ROUTE_HOME(state, value) {
      state.lastRouteHome = value;
    },
    SET_LAST_ROUTE_ENV_STATS(state, value) {
      state.lastRouteEnvStats = value;
    },
    SET_LAST_ROUTE_GEOTOOLBOX(state, value) {
      state.lastRouteGeotoolboxSamples = value;
    },
  },
};
