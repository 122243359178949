export default {
  namespaced: true,
  state: {
    selectedSampleTypes: [],
    samplingFromDate: null,
    samplingToDate: null,
    selectedStatuses: [],
    selectedFarms: [],
    selectedCultives: [],
    selectedServices: [],
    selectedParcels: [],
    selectedClients: [],
    selectedStudies: [],
    selectedAnalyseTypes: [],
    selectedMonitoring: false, 
  },
  actions: {
    setSelectedSampleTypes({ commit }, value) {
      commit('setSelectedSampleTypes', value);
    },
    setSamplingFromDate({ commit }, value) {
      commit('setSamplingFromDate', value);
    },
    setSamplingToDate({ commit }, value) {
      commit('setSamplingToDate', value);
    },
    setSelectedStatuses({ commit }, value) {
      commit('setSelectedStatuses', value);
    },
    setSelectedFarms({ commit }, value) {
      commit('setSelectedFarms', value);
    },
    setSelectedCultives({ commit }, value) {
      commit('setSelectedCultives', value);
    },
    setSelectedServices({ commit }, value) {
      commit('setSelectedServices', value);
    },
    setSelectedParcels({ commit }, value) {
      commit('setSelectedParcels', value);
    },
    setSelectedClients({ commit }, value) {
      commit('setSelectedClients', value);
    },
    setSelectedStudies({ commit }, value) {
      commit('setSelectedStudies', value);
    },
    setSelectedAnalyseTypes({ commit }, value) {
      commit('setSelectedAnalyseTypes', value);
    },
    setSelectedMonitoring({ commit }, value) {
      commit('setSelectedMonitoring', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setSelectedSampleTypes(state, value) {
      state.selectedSampleTypes = value;
    },
    setSamplingToDate(state, value) {
      state.samplingToDate = value;
    },
    setSamplingFromDate(state, value) {
      state.samplingFromDate = value;
    },
    setSelectedStatuses(state, value) {
      state.selectedStatuses = value;
    },
    setSelectedFarms(state, value) {
      state.selectedFarms = value;
    },
    setSelectedCultives(state, value) {
      state.selectedCultives = value;
    },
    setSelectedServices(state, value) {
      state.selectedServices = value;
    },
    setSelectedParcels(state, value) {
      state.selectedParcels = value;
    },
    setSelectedStudies(state, value) {
      state.selectedStudies = value;
    },
    setSelectedAnalyseTypes(state, value) {
      state.selectedAnalyseTypes = value;
    },
    setSelectedMonitoring(state, value) {
      state.selectedMonitoring = value;
    },
    clearFilters(state) {
      state.selectedParcels = [];
      state.selectedFarms = [];
      state.selectedSampleTypes = [];
      state.selectedServices = [];
      state.selectedCultives = [];
      state.selectedStatuses = [];
      state.samplingToDate = null;
      state.samplingFromDate = null;
      state.selectedClients = [];
      state.selectedStudies = [];
      state.selectedAnalyseTypes = [];
      state.selectedMonitoring = false;
    },
    setSelectedClients(state, value) {
      state.selectedClients = value;
    },
  },
};
